<template>
  <div>
    <list-template
      :search-config="searchConfig"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      :isDownload="true"
      @onAdd="$router.push('./add')"
      @onExportData="handDownload"
      @onQrCode="handleShow"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange"></list-template>
      <Dialog :visible.sync="visible" title="二维码" size="small" :showFooter="false" :showHeader="false" :closeOnClickModal="false" :beforeSubmit="onBeforeClose">
      <div class="dialog-code">
        <img :src="code" alt="二维码">
      </div>
      <div class="dialog-btn">
        <el-button type="primary" @click="handleClose">关闭</el-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { JSONToExcel, changeOption } from "@/utils";
import Dialog from "@/components/common/Dialog";
import http from "@/api";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  components: { Dialog },
  mixins: [tableListMixin],
  created() {
    this.getConfig()
    console.log(process.env.VUE_APP_URL_BASE);
  },
  methods: {
    getConfig() {
      http.get('/zhaosheng/api/crm/thread-config').then(res => {
        const { status = [], user_list = [] } = res
        this.searchConfig[1].options = changeOption({ list: status, label: 'name', value: 'id' })
        this.searchConfig[2].options = changeOption({ list: user_list, label: 'name', value: 'id' })
      })
    },
    getData() {
      this.getDataApi('/zhaosheng/api/crm/thread-list')
    },
    onReset() {},
    // onSearch() {},
    handleClick(row, handle) {
      this.$router.push(`./detail?id=${row.id}`)
    },
    handleShow() {
      this.visible = true
      if(!this.code) {
        const url = process.env.VUE_APP_URL_WEB + '/crm/form?id=' + this.$store.getters.userInfo.userinfo.id
        this.code = process.env.VUE_APP_URL_THREE + '/api/qrcode?url=' + encodeURIComponent(url)
      }
    },
    handleClose() {
      this.onBeforeClose(() => {
        this.visible = false
      })
    },
    onBeforeClose(done) {
      done()
    },
    handDownload() {
      http.get('/zhaosheng/api/crm/thread-list', {...this.search, export: 1}).then(res => {
        const config = {}
        config.tips = true
        config.data = res
        config.name = '线索池数据'
        config.title = [{ label:'跟进状态', value: 'status_name' },{ label:'客户姓名', value: 'name' },{ label:'手机号', value: 'phone' },{ label:'客户与学生的关系', value: 'relationship_name' },{ label:'计划报名校区', value: 'school_name' },{ label:'线索归属人', value: 'creator' },{ label:'线索提交时间', value: 'created_at' }]
        JSONToExcel(config)
      })

    }
  },
  data() {
    return {
      code: '',
      visible: false,
      searchConfig: [
        {
          tag: 'input',
          placeholder: '请输入手机号',
          prop: 'phone',
          type: 'number'
        },
        {
          tag: 'select',
          placeholder: '请选择跟进状态',
          prop: 'status',
          options: []
        },
        {
          tag: 'select',
          placeholder: '请选择线索归属人',
          prop: 'creator',
          options: []
        },
      ],
      tableConfig: [
        {
          prop: 'status_name',
          label: '跟进状态',
        },
        {
          prop: 'name',
          label: '客户姓名',
        },
        {
          prop: 'phone',
          label: '手机号',
        },
        {
          prop: 'relationship_name',
          label: '客户与学生的关系',
          render(row) {
            return row.real_relationship || row.relationship_name
          }
        },
        {
          prop: 'school_name',
          label: '计划报名校区',
        },
        {
          prop: 'creator',
          label: '线索归属人',
        },
        {
          prop: 'created_at',
          label: '线索提交时间',
        },
        {
          label: '操作',
          width: '100px',
          handle: true,
          fixed: 'right',
          render() {
            return ['跟进']
          }
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &-code {
    width: 280px;
    height: 280px;
    margin: 10px auto;
    background-color: darkgray;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-btn {
    text-align: center;
  }
}
</style>
